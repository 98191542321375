<template>
  <div>
    <b-form-row>
      <b-col sm="10" md="6" lg="5" xl="4">
        <b-form-group>
          <h4 class="page-sub-header">Action link text</h4>
          <div class="floating-input-field">
            <b-form-input
              id="action-link-text"
              :class="{
                'is-invalid': !data.actionLinkTxt && formSubmitted,
              }"
              type="text"
              placeholder=" "
              v-model="data.actionLinkTxt"
              required
            ></b-form-input>
            <label for="action-link-text">Action link text</label>
            <b-form-invalid-feedback
              class="d-block"
              v-if="!data.actionLinkTxt && formSubmitted"
              >Action link text required.</b-form-invalid-feedback
            >
          </div>
        </b-form-group>
      </b-col>
    </b-form-row>
  </div>
</template>
<script>
export default {
  name: 'RecreationWidget',
  props: ['data', 'formSubmitted']
}
</script>
